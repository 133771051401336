import React from 'react'
import { Link } from "react-router-dom"

export const Page404 = () => {
  return (
    <>
      <h1 className='center'>404 NOT FOUND</h1>
      <p className='center'>お探しのページが見つかりませんでした。</p>
      <div className='center'>
        <Link to="/">Topに戻る</Link>
      </div>

    </>
  )
}