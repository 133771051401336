import dayjs from "dayjs";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper";

const MainView = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s: any, time: number, progress: number) => {
    if (progressCircle !== null && progressContent !== null) {
      if (progressCircle && progressCircle.current) {
        const p = progressCircle?.current as HTMLElement
        p.style.setProperty('--progress', String(1 - progress));
      }
      if (progressContent && progressContent.current) {
        const p2 = progressContent.current as HTMLElement
        p2.textContent = `${Math.ceil(time / 1000)}s`;
      }


    }
  };
  return (

    <div className="main-view">
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        // navigation
        slidesPerView={"auto"}
        centeredSlides={true}
        pagination={{ clickable: true }}
        speed={1500}
        className="mySwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        loop={true}
      // scrollbar={{ draggable: true }}
      >
        <SwiperSlide><a><img loading="eager" src="/assets/slider02.jpg" /></a></SwiperSlide>
        <SwiperSlide><a><img loading="eager" src="/assets/highvolt.jpg" /></a></SwiperSlide>
        <SwiperSlide><a><img loading="eager" src="/assets/bed.jpg" /></a></SwiperSlide>
        <SwiperSlide><a><img loading="eager" src="/assets/slider01.jpg" /></a></SwiperSlide>
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
      <svg className="wave" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 50" enableBackground="new 0 0 1000 50">
        <path fill="rgba(0, 125, 150, 1)">
          <animate dur="5s" repeatCount="indefinite" attributeName="d" attributeType="XML" values="
      M1920.5,28.1c-55.6,14.7-90.5,16.5-144,0c-49.9-15.4-70.8-6.4-121.1,4.7c-52.3,11.6-73.9-24.9-122-31.7
			c-41.2-5.9-69.6,35.3-110.5,35.1c-39.2-0.1-48.4-18.1-99.7-18.1c-60.2,0-89.3,23.5-132.7,10.4c-50.7-15.3-75.5-20.6-124.5-6.3
			c-50.6,14.8-91,5.9-140.1-8c-93.8-26.5-163,15-254.5,16.9C592,32.7,525-7.1,442.9,13.3c-86.5,21.5-151,26.2-239.1,2.4
			C138.3-2,58.7-8.4,0.5,28.1v29h1920V28.1z;
      
      M1920.5,26.1c-55.6,14.7-95.5,18.5-149,2c-49.9-15.4-65.8-6.4-116.1,4.7c-52.3,11.6-85.9-24.9-134-31.7
			c-41.2-5.9-67.6,35.3-108.5,35.1c-39.2-0.1-48.4-19.1-99.7-19.1c-60.2,0-89.3,24.5-132.7,11.4c-50.7-15.3-75.5-20.6-124.5-6.3
			c-50.6,14.8-111,2.9-160.1-11c-93.8-26.5-143,15-234.5,16.9C582,29.7,495-8.1,412.9,12.3c-86.5,21.5-133,35.2-221.1,11.4
			C126.3,6,58.7-10.4,0.5,26.1v29h1920V26.1z;
      
      M1920.5,23.8c-55.6,14.7-115.5,18.5-169,2c-49.9-15.4-65.8-6.4-116.1,4.7c-52.3,11.6-85.9-18.9-134-25.7
			c-41.6-5.9-67.6,27.3-108.5,27.1c-39.2-0.1-51.4-17.1-102.7-17.1c-60.2,0-83.3,27.5-126.7,14.4C1112.8,13.9,1090,1.6,1041,15.9
			c-50.6,14.8-106,6.9-155.1-7c-93.8-26.5-153,18-244.5,19.9C562,30.4,479-7.4,396.9,13c-86.5,21.5-137,29.2-225.1,5.4
			C106.3,0.7,58.7-12.7,0.5,23.8v29h1920V23.8z;
			
      M1920.5,26.1c-55.6,14.7-95.5,18.5-149,2c-49.9-15.4-65.8-6.4-116.1,4.7c-52.3,11.6-85.9-24.9-134-31.7
			c-41.2-5.9-67.6,35.3-108.5,35.1c-39.2-0.1-48.4-19.1-99.7-19.1c-60.2,0-89.3,24.5-132.7,11.4c-50.7-15.3-75.5-20.6-124.5-6.3
			c-50.6,14.8-111,2.9-160.1-11c-93.8-26.5-143,15-234.5,16.9C582,29.7,495-8.1,412.9,12.3c-86.5,21.5-133,35.2-221.1,11.4
			C126.3,6,58.7-10.4,0.5,26.1v29h1920V26.1z;
			
      M1920.5,28.1c-55.6,14.7-90.5,16.5-144,0c-49.9-15.4-70.8-6.4-121.1,4.7c-52.3,11.6-73.9-24.9-122-31.7
			c-41.2-5.9-69.6,35.3-110.5,35.1c-39.2-0.1-48.4-18.1-99.7-18.1c-60.2,0-89.3,23.5-132.7,10.4c-50.7-15.3-75.5-20.6-124.5-6.3
			c-50.6,14.8-91,5.9-140.1-8c-93.8-26.5-163,15-254.5,16.9C592,32.7,525-7.1,442.9,13.3c-86.5,21.5-151,26.2-239.1,2.4
			C138.3-2,58.7-8.4,0.5,28.1v29h1920V28.1z;">
          </animate>
        </path>
        <path fill="rgba(175, 223, 228, 1)">
          <animate dur="7s" repeatCount="indefinite" attributeName="d" attributeType="XML" values="
      M1920.5,23.8c-55.6,14.7-115.5,18.5-169,2c-49.9-15.4-65.8-6.4-116.1,4.7c-52.3,11.6-85.9-18.9-134-25.7
			c-41.6-5.9-67.6,27.3-108.5,27.1c-39.2-0.1-51.4-17.1-102.7-17.1c-60.2,0-83.3,27.5-126.7,14.4C1112.8,13.9,1090,1.6,1041,15.9
			c-50.6,14.8-106,6.9-155.1-7c-93.8-26.5-153,18-244.5,19.9C562,30.4,479-7.4,396.9,13c-86.5,21.5-137,29.2-225.1,5.4
			C106.3,0.7,58.7-12.7,0.5,23.8v29h1920V23.8z;
			
      M1920.5,26.1c-55.6,14.7-95.5,18.5-149,2c-49.9-15.4-65.8-6.4-116.1,4.7c-52.3,11.6-85.9-24.9-134-31.7
			c-41.2-5.9-67.6,35.3-108.5,35.1c-39.2-0.1-48.4-19.1-99.7-19.1c-60.2,0-89.3,24.5-132.7,11.4c-50.7-15.3-75.5-20.6-124.5-6.3
			c-50.6,14.8-111,2.9-160.1-11c-93.8-26.5-143,15-234.5,16.9C582,29.7,495-8.1,412.9,12.3c-86.5,21.5-133,35.2-221.1,11.4
			C126.3,6,58.7-10.4,0.5,26.1v29h1920V26.1z;
      
      M1920.5,28.1c-55.6,14.7-90.5,16.5-144,0c-49.9-15.4-70.8-6.4-121.1,4.7c-52.3,11.6-73.9-24.9-122-31.7
			c-41.2-5.9-69.6,35.3-110.5,35.1c-39.2-0.1-48.4-18.1-99.7-18.1c-60.2,0-89.3,23.5-132.7,10.4c-50.7-15.3-75.5-20.6-124.5-6.3
			c-50.6,14.8-91,5.9-140.1-8c-93.8-26.5-163,15-254.5,16.9C592,32.7,525-7.1,442.9,13.3c-86.5,21.5-151,26.2-239.1,2.4
			C138.3-2,58.7-8.4,0.5,28.1v29h1920V28.1z;
      
      M1920.5,26.1c-55.6,14.7-95.5,18.5-149,2c-49.9-15.4-65.8-6.4-116.1,4.7c-52.3,11.6-85.9-24.9-134-31.7
			c-41.2-5.9-67.6,35.3-108.5,35.1c-39.2-0.1-48.4-19.1-99.7-19.1c-60.2,0-89.3,24.5-132.7,11.4c-50.7-15.3-75.5-20.6-124.5-6.3
			c-50.6,14.8-111,2.9-160.1-11c-93.8-26.5-143,15-234.5,16.9C582,29.7,495-8.1,412.9,12.3c-86.5,21.5-133,35.2-221.1,11.4
			C126.3,6,58.7-10.4,0.5,26.1v29h1920V26.1z;
			
      M1920.5,23.8c-55.6,14.7-115.5,18.5-169,2c-49.9-15.4-65.8-6.4-116.1,4.7c-52.3,11.6-85.9-18.9-134-25.7
			c-41.6-5.9-67.6,27.3-108.5,27.1c-39.2-0.1-51.4-17.1-102.7-17.1c-60.2,0-83.3,27.5-126.7,14.4C1112.8,13.9,1090,1.6,1041,15.9
			c-50.6,14.8-106,6.9-155.1-7c-93.8-26.5-153,18-244.5,19.9C562,30.4,479-7.4,396.9,13c-86.5,21.5-137,29.2-225.1,5.4
			C106.3,0.7,58.7-12.7,0.5,23.8v29h1920V23.8z;">
          </animate>
        </path>
      </svg>
    </div >
  )
}
export default MainView