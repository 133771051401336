import React from "react";

const Aisatsu = () => {
  return (
    <>
      <section className="aisatsu">
        <div>
          <h2 className="center">院長挨拶</h2>
          <div>
            美容鍼やハイボルテージなど、体に合わせた施術をいたします。体に合わせた施術を行うことで、根本的な改善に効果があります。<br />
            痛みがあるときはもちろん、予防にも繋がりますので、ぜひ一度ご来院ください。
            <div style={{ margin: '20px 0 0' }} className="right">
              院長 赤堀 智昭
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Aisatsu