import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/css/Header.module.css";

const Header = () => {
  const clickEvt = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement
    target?.classList.toggle('open')
  }
  const closeEvt = (e: any) => {
    const target = e.target as HTMLElement
    target.closest('details')?.removeAttribute('open')
  }
  return (
    <header>
      <div>
        <Link className={styles.Logo} to="/"><img src="/assets/kakegawa-logo.png?test=111" /></Link>
      </div>
      <div className="sp-hidden">
        <nav>
          <ul>
            <li>
              <summary onClick={((e: React.MouseEvent) => { clickEvt(e) })}>施術について</summary>
              <ul>
                <li><a href="/pages/beauty">美容鍼</a></li>
                <li><a href="/pages/highvolt">ハイボルテージ</a></li>
                <li><a href="/pages/manipulative">鍼灸・整体</a></li>
                <li><a href="/pages/whitening">ホワイトニング</a></li>
              </ul>
            </li>
            <li>
              <summary onClick={((e: React.MouseEvent) => { clickEvt(e) })}>当院について</summary>
              <ul>
                <li><a href="/pages/about">院長について</a></li>
                <li><a href="/pages/about#housecall">訪問診療について</a></li>
                <li><a href="/pages/about#trafficAccident">交通事故関連について</a></li>
                <li><a href="/pages/about#access">アクセス</a></li>
              </ul>
            </li>
            {/* <li><a href="/news">お知らせ</a></li> */}
            <li><a href="/pages/price">料金案内</a></li>
            <li><a href="/pages/information">お問い合わせ</a></li>
          </ul>
        </nav>
      </div>
      <div className="sp-nav">
        <details>
          <summary className="menu-icon"><span className="navicon"></span></summary>
          <div className="bg" onClick={(e) => { closeEvt(e) }}></div>
          <div>
            <nav>
              <ul>
                <li>
                  <summary onClick={((e: React.MouseEvent) => { clickEvt(e) })}>施術について</summary>
                  <ul>
                    <li><a href="/pages/beauty">美容鍼</a></li>
                    <li><a href="/pages/highvolt">ハイボルテージ</a></li>
                    <li><a href="/pages/manipulative">鍼灸・整体</a></li>
                    <li><a href="/pages/whitening">ホワイトニング</a></li>
                  </ul>
                </li>
                <li>
                  <summary onClick={((e: React.MouseEvent) => { clickEvt(e) })}>当院について</summary>
                  <ul>
                    <li><a href="/pages/about">院長について</a></li>
                    <li><a href="/pages/about#housecall">訪問診療について</a></li>
                    <li><a href="/pages/about#trafficAccident">交通事故関連について</a></li>
                    <li><a href="/pages/about#access">アクセス</a></li>
                  </ul>
                </li>
                {/* <li><a href="/news">お知らせ</a></li> */}
                <li><a href="/pages/price">料金案内</a></li>
                <li><a href="/pages/information">お問い合わせ</a></li>
              </ul>
            </nav>
          </div>
        </details>
      </div>
    </header>
  )
}
export default Header