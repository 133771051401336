import React, { useEffect, useState } from "react"
import MainView from "../Components/MainView";
import Header from "../Components/Header";
import { useParams } from "react-router-dom";
import { Page404 } from "./Page404";
import { getPageData } from "../hooks/getPageData";
import parse, { domToReact } from 'html-react-parser'
import { PageDataType } from "../types/pageDataType";

const Page = () => {
  const { page } = useParams()
  const [pageData, setPageData] = useState<PageDataType>()
  const [pageStatus, setPageStatus] = useState<boolean>(true)
  useEffect(() => {
    fetch(`/PageData/${page}.json`)
      .then(res => res.json())
      .then(res => {
        setPageData(res)
        setTimeout(() => {
          const link = window.location.href.split('#')
          if (link[1]) {
            console.log(link[1])
            const element = document.getElementById(link[1]);
            element?.scrollIntoView({
              behavior: 'smooth'
            });
          }

        }, 500)
        if (!res.status) {
          setPageStatus(false)
        }
      })
      .catch(error => {
        console.error('error')
        setPageStatus(false)
      })
  }, [])


  if (pageStatus == true) {
    return (
      <>
        <div className={page + ' page'}>
          <Header />
          {pageData?.status &&
            <>
              <h2 className="page-title center">{pageData?.title}</h2>
              <div className="page-contents">
                {/* test */}
                {(() => {
                  const content: string = pageData?.content
                  return (
                    <>
                      {parse(content)}
                    </>
                  )
                })()}

                {/* test */}
              </div>
            </>
          }
          {pageData?.status == false &&
            <>
              <p>現在このページは公開されていないか存在していません。</p>
            </>
          }
          {!pageData &&
            <>
              <p className="center">Loading....</p>
            </>
          }
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={page + ' page'}>
          <Header />
          <Page404 />
        </div>
      </>

    )
  }

}
export default Page