import React from "react";

const StoreDate = () => {
  return (
    <>
      <section className="store-date">
        <h2 className="center">診察日</h2>
        <ul>
          <li>
            <span className="center">曜日</span>
            <span className="center">月/祝</span>
            <span className="center">火</span>
            <span className="center">水</span>
            <span className="center">木</span>
            <span className="center">金</span>
            <span className="center">土</span>
            <span className="center">日</span>
          </li>
          <li>
            <span className="center">午前</span>
            <span className="center">×</span>
            <span className="center">●</span>
            <span className="center">●</span>
            <span className="center">●</span>
            <span className="center">●</span>
            <span className="center">●</span>
            <span className="center">●</span>
          </li>
          <li>
            <span className="center">午後</span>
            <span className="center">×</span>
            <span className="center">●</span>
            <span className="center">●</span>
            <span className="center">●</span>
            <span className="center">●</span>
            <span className="center">●</span>
            <span className="center">×</span>
          </li>
        </ul>
        <div className="right">
          <span className="left" style={{ display: 'inline-block' }}>
            診察時間: 午前8:00〜12:00(日曜日のみ8:00〜12:00） 午後14:30〜19:00<br />
            急患時にはご相談ください。往診可能ですのでご相談ください。
          </span>

        </div>
      </section>
    </>
  )
}
export default StoreDate