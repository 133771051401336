import React from "react";

const News = () => {
  return (
    <>
      <section className="news">
        <div>
          <h2 className="center">お知らせ</h2>
          <ul>
            <li><p className="date">2023/01/01</p><p>タイトルタイトルタイトル</p></li>
            <li><p className="date">2023/01/01</p><p>タイトルタイトルタイトル</p></li>
            <li><p className="date">2023/01/01</p><p>タイトルタイトルタイトル</p></li>
            <li><p className="date">2023/01/01</p><p>タイトルタイトルタイトル</p></li>
          </ul>
        </div>
      </section>
    </>
  )
}
export default News