import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './Pages/About';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Top from './Pages/Top';
import Page from './Pages/Page';
import { Page404 } from './Pages/Page404';
import NewsPage from './Pages/NewsPage';

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/about" element={<About />} />
          <Route path="/pages/:page" element={<Page />} />
          <Route path="/news/" element={<NewsPage />} />
          <Route path="/news/:id" element={<Page />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
