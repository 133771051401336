import React from "react";
import MainView from "../Components/MainView";
import Header from "../Components/Header";
import News from "../Components/News";
import Aisatsu from "../Components/Aisatsu";
import First from "../Components/First";
import StoreDate from "../Components/StoreDate";
import Access from "../Components/Access";
import Works from "../Components/Works";

const Top = () => {


  return (
    <div className="top">
      <MainView />
      <Header />
      <StoreDate />
      {/* <News /> */}
      <Aisatsu />
      <Works />
      <First />
      <Access />
    </div>
  )
}
export default Top