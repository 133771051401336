import React from "react";

const First = () => {
  return (
    <>
      <section className="first">
        <div>
          <h2 className="center">はじめて来院される方へ</h2>
          <div>
            <div>
              <h3 className="center">しっかりと問診・検査をします</h3>
              <div>掛川接骨院では問診票に記入して頂き、現在の症状やお身体の状態、生活習慣について詳しくお聞きし、姿勢検査・可動域検査・筋力検査・神経検査を行いお身体の痛み、 不調の根本原因を明確にします。姿勢検査では姿勢分析システムを使用し姿勢・骨格のゆがみをレポート画像を見ながら説明します。</div>
            </div>
            <div>
              <h3 className="center">わかりやすい説明をします</h3>
              <div>掛川接骨院では、問診・検査によって明らかになった根本的原因や最適な施術方法や痛み、不調が改善されるであろう期間、費用を患者様にわかりやすく説明します。</div>
            </div>
            <div>
              <h3 className="center">患者様ひとりひとりに合った施術をします</h3>
              <div>掛川接骨院では同じ腰痛・同じ肩凝りであっても、患者様一人一人原因は違います。患者様自身の痛いや不調の根本原因を取り除くために整体・鍼灸治療・電気治療・筋肉調整の中で患者様に一番最適だと思われる治療を提案させて頂きます。</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default First