import React from "react";
import { Link } from "react-router-dom";

const Works = () => {
  return (
    <>
      <section className="works">
        <div>
          <h2 className="center">掛川接骨院の施術</h2>
          <ul>
            <li>
              <div><img loading="lazy" src="/assets/biyousin_02.png" alt="美容鍼" /></div>
              <div>
                <h3>美容鍼</h3>
                <div>患者様お一人お一人のお悩みを聞き、お悩みに沿った説明をさせていただき、治療頻度、セルフケアの方法を提案させていただき、ご納得いただいた上で施術に入ります。お顔というデリケートな部分への施術ですので優しく丁寧に行っていきます。</div>
                <div className="right">
                  <Link to="/pages/beauty"> ＞ 詳しく見る</Link>
                </div>
              </div>
            </li>
            <li>
              <div><img loading="lazy" src="/assets/highvolt.jpg" alt="ハイボルテージ" /></div>
              <div>
                <h3>ハイボルテージ</h3>
                <div>なんとかしたい痛みの原因などを特定したり、鎮痛の効果があります。ハイボルテージ治療で特定し、整体や鍼灸などで痛みの原因を根本的改善していきます。</div>
                <div className="right">
                  <Link to="/pages/highvolt"> ＞ 詳しく見る</Link>
                </div>
              </div>
            </li>
            <li>
              <div><img loading="lazy" src="/assets/manipulative01.jpg" alt="鍼灸・整体" /></div>
              <div>
                <h3>鍼灸・整体</h3>
                <div>鍼に電気を通す通電鍼、鍼の上にもぐさをつける灸頭鍼、鍼を刺したまま関節 を動かす運動鍼など患者さまの症状に合った技法を選択し施術します。自律神 軽の乱れ、肩こり、五十肩、生理痛、生理不順 、腰痛の方にお勧めです。</div>
                <div className="right">
                  <Link to="/pages/manipulative"> ＞ 詳しく見る</Link>
                </div>
              </div>
            </li>
            <li>
              <div><img loading="lazy" src="/assets/1270809_s.jpg" alt="ホワイトニング" /></div>
              <div>
                <h3>ホワイトニング</h3>
                <div>セルフホワイトニングは、美しい白い歯を手に入れるための効果的な方法ですが、歯の健康と安全性を保つためには、適切な使用方法と指導が不可欠です。掛川接骨院では、患者さんのニーズに合わせた最良のホワイトニングアプローチを提供し、美しい笑顔と健康な歯をサポートします。</div>
                <div className="right">
                  <Link to="/pages/whitening"> ＞ 詳しく見る</Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}
export default Works