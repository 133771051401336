import React from "react";
import Header from "../Components/Header";
import News from '../Components/News';
const NewsPage = () => {
  return (
    <>
      <Header />
      <News />
    </>
  )
}
export default NewsPage