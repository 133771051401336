import React from "react";

const Access = () => {
  let iframeSource = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52430.26974808182!2d137.91173454863278!3d34.7834976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f1!3m3!1m2!1s0x601afb4925913457%3A0x3e0930041f8ee94!2z5o6b5bed5o6l6aqo6ZmiKOODm-ODr-OCpOODiOODi-ODs-OCsOWwjuWFpeW6lyk!5e0!3m2!1sja!2sjp!4v1686420522844!5m2!1sja!2sjp" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
  return (
    <>
      <section className="access">
        <h2 className="center">アクセス</h2>
        <div>
          <div style={{ position: 'relative' }} dangerouslySetInnerHTML={{ __html: iframeSource }}></div>
        </div>
      </section>
    </>
  )
}
export default Access