import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div>
        <nav>
          <ul>
            <li>
              <summary>施術について</summary>
              <ul>
                <li><a href="/pages/beauty">美容鍼</a></li>
                <li><a href="/pages/highvolt">ハイボルテージ</a></li>
                <li><a href="/pages/manipulative">鍼灸・整体</a></li>
                <li><a href="/pages/whitening">ホワイトニング</a></li>
              </ul>
            </li>
            <li>
              <summary>当院について</summary>
              <ul>
                <li><a href="/pages/about">院長について</a></li>
                <li><a href="/pages/about#housecall">訪問診療について</a></li>
                <li><a href="/pages/about#trafficAccident">交通事故関連について</a></li>
                <li><a href="/pages/about#access">アクセス</a></li>
                <li><a href="/pages/privacy_policy">プライバシーポリシー</a></li>
              </ul>
            </li>
            {/* <li><a href="/news">お知らせ</a></li> */}
            <li><a href="/pages/price">料金案内</a></li>
            <li><a href="/pages/information">お問い合わせ</a></li>
          </ul>
        </nav>
      </div>
      <div>
        <div data-hover-link style={{ backgroundImage: 'url(/assets/24357898_s.jpg)' }}>
          <a href="tel:0537-21-3978">電話で予約</a>
        </div>
        &copy;{dayjs().format('YYYY')} {'掛川接骨院'}
      </div>
    </footer>
  )
}
export default Footer